import { loadView } from '../utils.js'

export const routes = [
	{
		path: '/',
		component: loadView('Bold'),
		meta: {
			title: 'BOLD',
			description:
				'BOLD aims to deliver protection against inflation, giving optimal risk-adjusted exposure to bitcoin and gold.',
			sitemap: {
				priority: 1.0,
				changefreq: 'always'
			}
		}
	},
	{
		path: '/about',
		component: loadView('About'),
		meta: {
			title: 'About BTAM',
			description: 'About ByteTree Asset Management.',
			sitemap: {
				priority: 0.8
			}
		}
	},
	{
		path: '/bold',
		component: loadView('Bold'),
		meta: {
			title: 'BOLD',
			description:
				'BOLD aims to deliver protection against inflation, giving optimal risk-adjusted exposure to bitcoin and gold.',
			sitemap: {
				priority: 0.9,
				changefreq: 'always'
			}
		}
	},
	{
		path: '/bold-investor-confirmation',
		component: loadView('BoldConfirmation'),
		meta: {
			sitemap: {
				ignoreRoute: true
			}
		}
	},
	{
		path: '/library',
		component: loadView('Library'),
		meta: {
			title: 'Library',
			description:
				'Our Library has moved to ByteTree.com',
			sitemap: {
				priority: 0.5,
				changefreq: 'always'
			}
		}
	},
	{
		path: '/mailing',
		component: loadView('MailingList'),
		meta: {
			title: 'Subscribe to our Research and News',
			description: 'The BTAM mailing list for research and investor news.',
			sitemap: {
				priority: 0.2
			}
		}
	},
	{
		path: '/subscribed',
		component: loadView('Subscribed'),
		meta: {
			sitemap: {
				ignoreRoute: true
			}
		}
	},
	{
		path: '/content',
		component: loadView('Library'),
		meta: {
			title: 'Library',
			description:
				'Our Library has moved to ByteTree.com',
			sitemap: {
				priority: 0.5,
				changefreq: 'always'
			}
		}
	},
	{
		path: '/privacy',
		component: loadView('Page'),
		props: {
			loading: 'privacy'
		},
		meta: {
			title: 'Privacy Statement',
			description: 'Information about how we handle your data and GDPR.',
			sitemap: {
				priority: 0.1
			}
		}
	},
	{
		path: '/content/tags',
		component: loadView('Library'),
		meta: {
			title: 'Library',
			description:
				'Our Library has moved to ByteTree.com',
			sitemap: {
				ignoreRoute: true
			}
		}
	},
	{
		path: '/content/tags/:tag',
		component: loadView('Library'),
		meta: {
			title: 'Library',
			description:
				'Our Library has moved to ByteTree.com',
			sitemap: {
				ignoreRoute: true
			}
		}
	},
	{
		path: '/content/:page',
		component: loadView('Library'),
		meta: {
			title: 'Library',
			description:
				'Our Library has moved to ByteTree.com',
			sitemap: {
				ignoreRoute: true
			}
		}
	},
	{
		path: '/content/page/:page',
		component: loadView('Library'),
		meta: {
			title: 'Library',
			description:
				'Our Library has moved to ByteTree.com',
			sitemap: {
				ignoreRoute: true
			}
		}
	},
	{
		path: '/content/:year(\\d+)/:month/:title',
		component: loadView('Library'),
		meta: {
			title: 'Library',
			description:
				'Our Library has moved to ByteTree.com',
			sitemap: {
				ignoreRoute: true
			}
		}
	},
	{
		path: '/content/research/:page',
		component: loadView('Library'),
		meta: {
			title: 'Library',
			description:
				'Our Library has moved to ByteTree.com',
			sitemap: {
				ignoreRoute: true
			}
		}
	},
	{
		path: '*',
		component: loadView('Error'),
		meta: {
			sitemap: {
				ignoreRoute: true
			}
		}
	}
]
