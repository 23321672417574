/* eslint no-console:0 */

import Vue from 'vue'
import VueHead from 'vue-head'

Vue.use(VueHead)

import {
	default as ByteTree,
	Store,
	Router
} from '@cryptocomposite/bytetree-vue-shared-components'

import { isMobileOnly } from 'mobile-device-detect'

import App from './App.vue'
import createRouter from './router/index'
import pkg from '../package.json'
import siteconfig from '../siteconfig.json'
import { instanceProperties } from './utils.js'

Vue.config.devtools = true
Vue.config.productionTip = true

for (const helper in instanceProperties) {
	Vue.prototype[helper] = instanceProperties[helper]
}

Vue.use(ByteTree, {
	logging: true,
	endpoint: siteconfig
})

if (typeof window !== 'undefined') {
	window.dataLayer = window.dataLayer || []
}

Vue.mixin({
	methods: {
		gtag: function () {
			if (this.$root.canTrack && typeof window !== 'undefined') {
				window.dataLayer.push(arguments)
			}
		}
	}
})

new Vue({
	data: {
		showNav: false,
		apiHost: siteconfig.host,
		apiVersion: '0.0.0',
		terminalHost: siteconfig.terminal,
		homeHost: siteconfig.home,
		menuHome: siteconfig.btam,
		haveConnection: false,
		isMobile: isMobileOnly,
		siteVersion: pkg.version,
		numberDuration: 0,
		canTrack: false,
		pageTransition: 'fade',
		googleID: siteconfig.googleID || {},
		googleConfig: siteconfig.googleConfig || {}
	},
	store: Store,
	router: createRouter(Router),
	render: h => h(App)
}).$mount('#app')
