<script>
/* eslint no-console:0,vue/no-unused-components:0 */
import { ColorUpdater } from '@cryptocomposite/bytetree-vue-shared-components'
import { bulmaCssVariablesDefs } from '@cryptocomposite/bytetree-vue-shared-components/src/style/bulma-generated/bulma-colors'
import lightTheme from './style/theme/light.js'
import darkTheme from './style/theme/dark.js'
import '@cryptocomposite/bytetree-vue-shared-components/src/style/main.scss'

function loadPart(part) {
	return () =>
		import(/* webpackChunkName: "part-[request]" */ `@/components/${part}.vue`)
}

const Disclaimer = loadPart('Disclaimer')
const Signup = loadPart('Signup')
const updater = new ColorUpdater(bulmaCssVariablesDefs)

export default {
	name: 'app',
	components: {
		Disclaimer,
		Signup
	},
	data() {
		return {
			showProductsNav: false,
			theme: 'none',
			hideSignup: ['/mailing']
		}
	},
	methods: {
		setDarkTheme() {
			document.documentElement.dataset.theme = 'dark'
			this.setTheme(darkTheme)
		},
		setLightTheme() {
			document.documentElement.dataset.theme = 'light'
			this.setTheme(lightTheme)
		},
		setTheme(theme) {
			for (const [colorName, value] of Object.entries(theme)) {
				updater.updateVarsInDocument(colorName, value)
			}
		},
		showDisclaimer() {
			this.$buefy.modal.open({
				component: Disclaimer,
				events: {
					accept: () => {
						this.$root.canTrack = true
						localStorage.setItem('cookies', 'accepted')
						this.gtag('js', new Date())
						this.gtag('config', this.$root.googleID, this.$root.googleConfig)
						this.gtag('event', 'cookies_accepted')
						this.$router.afterEach(to =>
							this.gtag('page_view', this.$root.terminalHost + to.fullPath)
						)
					},
					decline: () => {
						this.$root.canTrack = false
					}
				},
				hasModalCard: true,
				canCancel: false,
				trapFocus: true
			})
		}
	},
	mounted() {
		console.log(
			`%cByteTreeAM.com v${this.$root.siteVersion} %c© Crypto Composite Ltd`,
			'font-size: 20px; color: #F85;',
			'font-size: 11px; color: #888;'
		)

		if (
			!(
				localStorage.getItem('cookies') === 'accepted' &&
				localStorage.getItem('disclaimer') === 'agree'
			)
		) {
			this.showDisclaimer()
		} else {
			this.$root.canTrack = true
			this.gtag('js', new Date())
			this.gtag('config', this.$root.googleID, this.$root.googleConfig)
			this.$router.afterEach(to =>
				this.gtag('page_view', this.$root.terminalHost + to.fullPath)
			)
		}

		setTimeout(() => {
			this.$root.numberDuration = 400
		}, 10000)

		this.$store.watch(
			state => state.themeManager.theme,
			theme => {
				if (theme === 'dark') {
					this.setDarkTheme()
				} else {
					this.setLightTheme()
				}
			}
		)

		let theme = localStorage.getItem('theme')
		if (theme === null) {
			if (matchMedia('(prefers-color-scheme: light)').matches) {
				theme = 'light'
			} else {
				theme = 'dark'
			}
			localStorage.setItem('theme', theme)
		}

		this.$store.dispatch('themeManager/setTheme', theme)
	}
}
</script>

<template>
	<div id="app">
		<TopMenu hide-user-control identity="btam" class="is-titling is-uppercase">
			<BNavbarItem v-if="$route.path !== '/'" tag="router-link" to="/">
				BOLD
			</BNavbarItem>
			<!-- <BNavbarItem tag="router-link" to="/bold">BOLD</BNavbarItem> -->
			<!-- <BNavbarDropdown collapsible aria-role="list">
				<template slot="label">
					<span>Funds</span>
					<span v-if="$root.showNav">…</span>
				</template>
				<BNavbarItem tag="router-link" to="/bitcoin-and-general">
					Bitcoin &amp; General Fund
				</BNavbarItem>
				<BNavbarItem tag="router-link" to="/bold">BOLD</BNavbarItem>
			</BNavbarDropdown> -->
			<!-- <BNavbarItem tag="a" :href="$root.terminalHost">Our Data</BNavbarItem>
			<BNavbarItem tag="a" :href="$root.homeHost">Our Research</BNavbarItem> -->
			<BNavbarItem tag="router-link" to="/about">About Us</BNavbarItem>
		</TopMenu>
		<section
			id="content-root"
			:class="$route.path === '/' ? 'banner-image' : 'no-banner-image'"
		>
			<router-view :key="$route.fullPath" class="view" id="content-main" />
		</section>
		<!-- <Signup v-if="!hideSignup.includes($route.path)" /> -->
		<BottomMenu
			:version="$root.siteVersion"
			api="x.x.x"
			suffix="Asset Management"
			connect-message="Connect with Bytetree"
			hide-signup-banner
			hide-right-connect-links
		>
			<template #navigation>
				<BMenuList label="Navigate">
					<BMenuItem
						label="Home"
						to="/"
						tag="router-link"
						aria-role="menuitem"
					/>
					<BMenuItem
						label="Our Research"
						:href="$root.homeHost"
						tag="a"
						aria-role="menuitem"
					/>
					<BMenuItem
						label="Our Data"
						:href="$root.terminalHost"
						tag="a"
						aria-role="menuitem"
					/>
				</BMenuList>
			</template>
			<div class="column">
				<BMenu>
					<BMenuList label="Funds">
						<!-- <BMenuItem
							to="/bitcoin-and-general"
							label="Bitcoin &amp; General Fund"
							tag="router-link"
							aria-role="menuitem"
						/> -->
						<BMenuItem
							to="/bold"
							label="BOLD"
							tag="router-link"
							aria-role="menuitem"
						/>
					</BMenuList>
				</BMenu>
			</div>
			<div class="column">
				<BMenu>
					<BMenuList label="Company">
						<BMenuItem
							to="/about"
							label="About Us"
							tag="router-link"
							aria-role="menuitem"
						/>
						<BMenuItem
							to="/privacy"
							label="Privacy Statement"
							tag="router-link"
							aria-role="menuitem"
						/>
					</BMenuList>
				</BMenu>
			</div>
			<!-- <template #left-connect-links>
				 <BMenuItem
					icon-pack="fad"
					icon="mailbox"
					label="Subscribe to our Research and News"
					to="/mailing"
					tag="router-link"
					aria-role="menuitem"
				/>

				<div />
			</template> -->
			<template #disclaimer>
				<div>
					ByteTree Asset Management Limited (FRN 933150) is an Appointed
					Representative of Strata Global Ltd (FRN 563834) which is Authorised
					and Regulated by the Financial Conduct Authority
				</div>
			</template>
		</BottomMenu>
	</div>
</template>

<style lang="stylus">
@import 'https://use.typekit.net/mfs4wxz.css'
@import '~@cryptocomposite/bytetree-vue-shared-components/src/assets/hkgrotesk/stylesheet.css'
html
	--type-family-titling hk_grotesksemibold, sans-serif
	--type-family-titling-weight 600

	--transparent rgba(255, 255, 255, 0)

	--chart-background var(--scheme-main-bis)
	--chart-foreground var(--text)

	--chart-positive var(--success)
	--chart-neutral var(--warning)
	--chart-negative var(--danger)

	--chart-progress-background var(--scheme-main-ter)
	--chart-progress-foreground var(--primary)

	--chart-line-1 var(--success)
	--chart-line-2 var(--primary-light)
	--chart-line-3 var(--warning)
	--chart-line-4 var(--danger)

	--chart-bitcoin #f2a900
	--chart-gold #B48517

	transition none

body
	text-rendering optimizeLegibility

table
	overflow hidden

.banner-image
	padding 0px 0px !important
	min-height 100% !important

.no-banner-image
	transition 0.8s

.adjust-icon
	position relative
	top 0.1em

.clickable
	cursor pointer

.is-titling
	font-family var(--type-family-titling)
	font-weight var(--type-family-titling-weight)
	text-transform var(--type-family-titling-transform)

.no-break
	white-space nowrap

.modal
	.modal-card-title
		@extend .is-titling
		font-size var(--size-large)
	.content ul
		list-style none
		margin-left 0
	.modal-card-foot
		padding 0.4rem

.loading-overlay .loading-background
	background-color rgba(0, 0, 0, 0.8)

article.stats, article.copy, div.chart-box
	header
		font-size var(--size-large)
		background-color var(--scheme-main)
		border-radius var(--radius) var(--radius) 0 0
		border-bottom 1px solid var(--scheme-main-bis)
		.chart-intro, .chart-subtitle
			font-family var(--type-family)
			font-weight 400
			font-size var(--size-normal)
			text-transform none
		&.level
			margin-bottom 0 !important
		.status
			font-size var(--size-small)
			& > span
				vertical-align middle
			& > .icon
				color var(--text)
				opacity 0.4
		.column.v-middle
			vertical-align: middle

	div.body
		padding 1rem
		orphans 3

		&.three-cols
			columns 3
			@media screen and (max-width: 1024px)
				columns 2
			@media screen and (max-width: 480px)
				columns 1
		&.two-cols
			columns 2
			@media screen and (max-width: 480px)
					columns 1
		&.has-background
			background var(--scheme-main)
			border-radius 0 0 var(--radius) var(--radius)


	div.main
		--size-large 1.4rem
		.value-main
			font-size var(--size-large)
		.value-sub
			font-size var(--size-small)
			margin 0 0.4rem
		.label-row
			font-size var(--size-medium)
		.new
			background-color var(--info) !important
		.table
			td
				white-space nowrap
				vertical-align middle
				border-color var(--scheme-main-bis)
			th
				white-space nowrap
				border-color var(--scheme-main-bis)

div.chart-box
	min-width 400px
	header
		background var(--scheme-main)
	footer
		background var(--scheme-main)
		border-radius 0 0 var(--radius) var(--radius)
	div.chart
		&.blurred
			svg
				filter: blur(16px)
		&.has-background
			background var(--scheme-main)
			border-radius 0 0 var(--radius) var(--radius)
		&.has-background-alt
			background var(--scheme-main)
		padding 0 !important
		width 100%
		height 600px
		&.large
			height 75vh
		&.small
			height 360px
		&.price-scale
			height calc(100vh - 540px)
			@media screen and (max-height: 1024px)
				height calc(100vh - 220px)
			@media screen and (max-width: 480px)
				height 80vh
			@media screen and (max-width: 1024px) and (orientation:landscape)
				height 100vh
		&.heatmap-scale
			height calc(100vh - 220px)
			@media screen and (max-width: 480px)
				height 80vh
			@media screen and (max-width: 1024px) and (orientation:landscape)
				height 100vh
		&.trends-scale
			height calc(100vh - 360px)
			@media screen and (max-width: 480px)
				height 80vh
			@media screen and (max-width: 1024px) and (orientation:landscape)
				height 100vh
		&.widescreen
			height calc((100vw - 300px) * 0.5625)

		li.amcharts-amexport-item
			font-family var(--type-family)
			font-weight 400
			font-size var(--size-normal)
			text-transform none
			svg
				path
					fill var(--text)
			background-color var(--scheme-main-ter)
			&:hover
				svg
					path
						fill var(--text-invert)
				background-color var(--primary-light)
			&.amcharts-amexport-item-level-0
				width 40px
				height 40px
				padding-top 4px
				min-height 1rem
			a
				color var(--text)
				&:hover
					color var(--text-invert)

::-webkit-scrollbar {
 	background-color: var(--navbar-background-color);
	width: 0.8rem;
	height: 0.8rem;
	&-track {
		box-shadow: inset 0 0 6px var(--shadow-light);
	}
	&-thumb {
		background-color: var(--navbar-item-active-background-color);
		border-radius: 0.8rem;
		border: 0.2rem solid var(--navbar-background-color);
		&:hover {
			background-color: var(--primary-light);
		}
	}
}

html[data-theme='dark']
	--transparent rgba(0, 0, 0, 0)

	--chart-background var(--scheme-main)
	--chart-line-1 var(--success-light)
	--chart-progress-background var(--scheme-main-bis)

	::-webkit-scrollbar {
	 	background-color: var(--navbar-background-color);
		&-thumb {
			background-color: var(--navbar-item-active-background-color);
			border: 0.2rem solid var(--navbar-background-color);
			&:hover {
				background-color: var(--primary);
			}
		}
	}
</style>
