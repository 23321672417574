import Vue from 'vue'
import { routes } from './routes.js'
import siteconfig from '../../siteconfig.json'

export default function createRouter(Router) {
	Vue.use(Router)

	return new Router({
		mode: 'history',
		base: __dirname,
		scrollBehavior(to) {
			if (to.hash) {
				return {
					selector: to.hash,
					offset: { x: 0, y: 150 }
				}
			}

			return { x: 0, y: 0 }
		},
		routes
	})
}
