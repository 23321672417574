import _clamp from 'lodash/clamp'

export function millionsAdapter(label, target) {
	if (target.dataItem && target.dataItem.value >= 1000000000) {
		return `${target.dataItem.value / 1000000000}B`
	}

	if (target.dataItem && target.dataItem.value >= 1000000) {
		return `${target.dataItem.value / 1000000}M`
	}

	if (target.dataItem && target.dataItem.value >= 1000) {
		return `${target.dataItem.value / 1000}K`
	}

	if (target.dataItem && target.dataItem.value <= -1000) {
		return `${target.dataItem.value / 1000}K`
	}

	if (target.dataItem && target.dataItem.value <= -1000000) {
		return `${target.dataItem.value / 1000000}M`
	}

	return label
}

export function populateTimestamps(array) {
	return array.map(element => {
		element.timestamp = new Date(element.timestamp)
		return element
	})
}

export function clamp(num, min, max) {
	return _clamp(num, min, max)
}

export function loadView(view) {
	return () =>
		import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

export function loadComponent(part) {
	return () =>
		import(
			/* webpackChunkName: "part-[request]" */ `@/components/${part}.vue`
		)
}

export const instanceProperties = {
	async $loadFile(fileName) {
		const url = `/data/${fileName}.json`
		const response = await fetch(url, { credentials: 'include' })
		return response.json()
	},
	$bitcoin() {
		return this.$store.getters['networkManager/blockchains'].bitcoin
	}
}
