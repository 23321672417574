// Buefy/Bulma Theme Color changes
// Light mode
//
// Has to use commonjs module api

const {
	hsl,
	hexMap
} = require('@cryptocomposite/bytetree-vue-shared-components/src/style/utils')

const baseHue = 231
const darkBlue = hsl(baseHue, 59, 15)

const background = hsl(baseHue, 10, 75)
const foreground = hsl(baseHue, 10, 12)

const schemeMain = background.clone().lighten(20)
const schemeBis = background.clone().lighten(15)
const schemeTer = background.clone().lighten(10)

const menu = darkBlue

const primary = hsl(230, 40, 30)
const info = hsl(206, 100, 54)
const success = hsl(154, 77, 44)
const warning = hsl(39, 99, 61)
const danger = hsl(348, 89, 41)

module.exports = hexMap({
	primary,
	background,
	'scheme-main': schemeMain,
	'scheme-main-bis': schemeBis,
	'scheme-main-ter': schemeTer,
	'scheme-invert': primary.clone().darken(5),
	link: info,
	'link-hover': primary.clone().lighten(20),
	'link-hover-border': primary.clone().lighten(20),
	'link-active': primary.clone().lighten(20),
	'link-active-border': primary.clone().lighten(20).saturate(70),
	'link-focus': primary.clone().lighten(20).saturate(30),
	'link-focus-border': primary.clone().lighten(20).saturate(70),
	text: foreground,
	info,
	success,
	warning,
	danger,
	dark: hsl(baseHue, 10, 10),
	light: hsl(baseHue, 10, 95),
	border: hsl(baseHue, 10, 86),
	'border-hover': hsl(baseHue, 20, 71),
	'border-light': hsl(baseHue, 10, 93),
	'text-strong': foreground,
	'footer-background-color': menu,
	'box-background-color': schemeTer,
	'navbar-background-color': menu,
	'navbar-item-color': foreground.clone().invert(),
	'navbar-item-hover-color': foreground.clone().invert().lighten(20),
	'navbar-item-hover-background-color': menu
		.clone()
		.lighten(10)
		.desaturate(10),
	'navbar-item-active-color': foreground.clone().invert().lighten(30),
	'navbar-item-active-background-color': menu
		.clone()
		.lighten(20)
		.desaturate(5),
	'navbar-dropdown-arrow': foreground.clone().invert(),
	'navbar-dropdown-background-color': menu.clone().lighten(5),
	'navbar-dropdown-item-hover-color': foreground.clone().invert().lighten(60),
	'navbar-dropdown-item-hover-background-color': menu.clone().lighten(10),
	'navbar-dropdown-item-active-color': foreground.clone().invert().lighten(60),
	'navbar-dropdown-item-active-background-color': menu.clone().lighten(20),
	'modal-card-head-background-color': schemeBis,
	'input-disabled-background-color': schemeMain,
	'input-disabled-border-color': schemeBis
})
